import { appConstants } from './app/app.constants'

export const appMeta = {
  home: {
    title: 'GRAHAL - Groupe de Recherche Art Histoire Architecture Littérature',
    description:
      'GRAHAL est une entreprise de services spécialisée dans la connaissance, la gestion et la valorisation du patrimoine.',
    path: ''
  },
  about: {
    title: 'À propos' + appConstants.META_TITLE_SUFFIX,
    description:
      'GRAHAL est une entreprise de services spécialisée dans la connaissance, la gestion et la valorisation du patrimoine.',
    path: 'a-propos'
  },
  team: {
    title: `L'équipe` + appConstants.META_TITLE_SUFFIX,
    description:
      `Le GRAHAL est une aventure humaine et humaniste d'historiens d'art, ` +
      `passionnés par la recherche historique et l'étude du patrimoine.`,
    path: 'l-equipe'
  },
  postList: {
    title: 'Actualités' + appConstants.META_TITLE_SUFFIX,
    description: 'Découvrez toutes nos actualités, filtrées par catégorie.',
    path: 'actualites'
  },
  studyList: {
    title: 'Ressources' + appConstants.META_TITLE_SUFFIX,
    description: `Moteur de recherche de ressources GRAHAL.`,
    path: 'references'
  },
  jobOfferList: {
    title: `Offres d'emploi` + appConstants.META_TITLE_SUFFIX,
    description: `Liste des offres d'emplois, stages et alternances chez GRAHAL.`,
    path: 'offres-d-emploi'
  },
  legalTerms: {
    title: 'Mentions légales' + appConstants.META_TITLE_SUFFIX,
    description: `Mentions légales du site web GRAHAL.`,
    path: 'mentions-legales'
  },
  privacyPolicy: {
    title: 'Politique de Confidentialité' + appConstants.META_TITLE_SUFFIX,
    description: `Politique de confidentialité du site web GRAHAL`,
    path: 'politique-de-confidentialite'
  },
  contact: {
    title: 'Contact' + appConstants.META_TITLE_SUFFIX,
    description: `Contactez-nous : GRAHAL, 23 Boulevard Possonnière, 75002 Paris. Tél : 01 49 24 09 57.`,
    path: 'contact'
  },

  // Services
  historicalStudies: {
    title: 'Études historiques' + appConstants.META_TITLE_SUFFIX,
    description:
      `Les études historiques visent à établir un dossier exhaustif d’études et recherches documentaires retraçant l’origine,` +
      `l’histoire et l’évolution d’un monument ou d’un site, d’un objet ou d’un paysage.`,
    path: 'prestations/r-et-e/etudes-historiques'
  },
  expertise: {
    path: 'prestations/r-et-e/expertise-et-assistance-a-maitrise-d-ouvrage',
    title:
      'Expertise et assistance à maîtrise d’ouvrage' +
      appConstants.META_TITLE_SUFFIX,
    description:
      `Dans le cadre des projets de réhabilitation ou de restructuration immobilière, ` +
      `le GRAHAL accompagne de son expertise les maîtrises d’ouvrage et les maîtrises d’œuvre`
  },
  archives: {
    path: 'prestations/i-doc/archives',
    title: 'Archives' + appConstants.META_TITLE_SUFFIX,
    description:
      `Traitement d'un arriéré d'archives, Rédaction de tableau de gestion ou de charte d'archivage,` +
      ` Préparation et contrôle de campagnes de numérisation`
  },
  libraries: {
    path: 'prestations/i-doc/bibliotheques',
    title: 'Bibliothèques' + appConstants.META_TITLE_SUFFIX,
    description: `Catalogage, indexation, exemplarisation, traitement matériel et autres presations dans les bibiothèques`
  },
  museums: {
    path: 'prestations/i-doc/musees',
    title: 'Musées' + appConstants.META_TITLE_SUFFIX,
    description:
      `Depuis 30 ans, la société Grahal réalise des prestations de documentation et de gestion ` +
      `de collections pour tout type de musée ou muséum `
  },
  documentation: {
    path: 'prestations/i-doc/documentation',
    title: 'Documentation' + appConstants.META_TITLE_SUFFIX,
    description:
      `Phototèques argentiques et numériques, recherches iconographiques, ` +
      `base de données documentaires et documentation patrimoniale`
  },
  urbanManagement: {
    path: 'prestations/i-pat/gestion-urbaine-et-paysagere-du-patrimoine',
    title:
      'Gestion urbaine et paysagère du patrimoine' +
      appConstants.META_TITLE_SUFFIX,
    description:
      `Nos études menées depuis plus de 30 ans nous permettent de développer des réflexions ` +
      `élargies sur la prise en compte du patrimoine dans le réglementaire et les projets urbains.`
  },
  protection: {
    path: 'prestations/i-pat/protection-et-labellisation-de-patrimoines',
    title:
      'Protection et labellisation de patrimoines' +
      appConstants.META_TITLE_SUFFIX,
    description:
      `Reconnaissance forte, argument de notoriété et de différenciation, la recherche de labels` +
      ` qualifie les patrimoines en inscrivant les territoires qui les portent dans des projets culturels et politiques exigeants.`
  },
  worldHeritage: {
    path: 'prestations/i-pat/patrimoine-mondial',
    title: 'Patrimoine mondial' + appConstants.META_TITLE_SUFFIX,
    description:
      `Expert auprès du Centre du Patrimoine mondial de l’UNESCO depuis 2009,` +
      ` le GRAHAL a réalisé avec succès plusieurs dossiers de candidature d’inscription sur la Liste du Patrimoine mondial.`
  },
  museumProjects: {
    path: 'prestations/i-pat/projets-museologiques-culturels-et-patrimoniaux',
    title:
      'Projets muséologiques, culturels et patrimoniaux' +
      appConstants.META_TITLE_SUFFIX,
    description:
      `Prestations spécifiques de définition de contenus culturels et muséologiques, ` +
      `et de positionnement conceptuel de projets de médiation.`
  },
  formationName: {
    path: 'prestations/grahal-academie/formations',
    title: 'Formation Name' + appConstants.META_TITLE_SUFFIX,
    description: `Description,` + `Suite...`
  },
  unsubscribe: {
    title: 'Désinscription de la newsletter' + appConstants.META_TITLE_SUFFIX,
    description: `Désinscription instantanée de la newsletter GRAHAL`
  },
  error404: {
    title: 'Erreur 404' + appConstants.META_TITLE_SUFFIX,
    description: `Erreur 404 : Impossible de trouver la page demandée. Nous vous conseillons de retourner à la page d'accueil.`,
    path: '404'
  }
}
