export const teamCarouselImages = [
  {
    portraitRetina: '../../../assets/img/home-membreequipe1.jpg',
    portraitMobile: '../../../assets/img/home-membreequipe1Mobile.jpg'
  },
  {
    portraitRetina: '../../../assets/img/home-membreequipe3.jpg',
    portraitMobile: '../../../assets/img/home-membreequipe3Mobile.jpg'
  }
]
