import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core'
import { Employee } from '../../../common/interfaces/employee'
import { environment } from '../../../../environments/environment'
import { TweenLite } from 'gsap'

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss']
})
export class EmployeeCardComponent implements AfterViewInit {
  @Input() employee: Employee
  @ViewChild('employeeCard', { static: false }) employeeCardEl: ElementRef

  storagePath: string = environment.storagePath

  ngAfterViewInit() {
    TweenLite.from(this.employeeCardEl.nativeElement, 2, {
      opacity: 0
    })
  }
}
