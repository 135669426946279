import { Injectable } from '@angular/core'
import { Title, Meta } from '@angular/platform-browser'
import { appMeta } from '../../../app.meta'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private title: Title, private meta: Meta) {}

  setTags(pageName: string): void {
    if (!appMeta[pageName]) {
      return
    }

    this.addMetaTags(
      appMeta[pageName].title,
      appMeta[pageName].description,
      appMeta[pageName].path
    )
  }

  addMetaTags(title: string, description: string, path: string) {
    this.title.setTitle(title)

    this.meta.updateTag({
      name: 'description',
      content: description
    })
    this.meta.updateTag({
      name: 'og:description',
      content: description
    })
    this.meta.updateTag({
      name: 'og:title',
      content: title
    })
    this.meta.updateTag({
      name: 'og:url',
      content: environment.frontUrl + path
    })
    this.meta.updateTag({
      name: 'og:image',
      content: environment.frontUrl + 'assets/img/grahal-logo-og.jpg'
    })
  }

  setImage(path: string): void {
    this.meta.updateTag({
      name: 'og:image',
      content: environment.storagePath + path
    })
  }
}
