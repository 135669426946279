import { Injectable } from '@angular/core'
import { HttpParams, HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  baseUrl = environment.apiBaseUrl + 'studies'
  constructor(private http: HttpClient) {}

  list({
    terms,
    year,
    department,
    domainId,
    subDomainId,
    page
  }: {
    terms?: string
    year?: string
    department?: string
    domainId?: string
    subDomainId?: string
    page?: string
  }): Observable<any> {
    let params = new HttpParams()

    if (terms) {
      params = params.set('terms', terms)
    }
    if (year) {
      params = params.set('year', year)
    }
    if (department) {
      params = params.set('department', department)
    }
    if (domainId) {
      params = params.set('domainId', domainId)
    }
    if (subDomainId) {
      params = params.set('subDomainId', subDomainId)
    }
    if (page) {
      params = params.set('page', page)
    }

    return this.http
      .get(this.baseUrl, {
        params: params
      })
      .pipe(
        map(res => {
          return res
        })
      )
  }
}
