import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class EventService {
  public routeChanged = new BehaviorSubject({ url: '/' })

  // We set behaviorSubject as false to prevent animating entrance on app
  public delayedRouteChanged = new BehaviorSubject(false)
}
