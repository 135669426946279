import { Component, OnInit, HostListener, ElementRef } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { Post } from '../../../common/interfaces/post'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { HttpParams } from '@angular/common/http'
import { Category } from '../../../common/interfaces/category'
import { appConstants } from '../../../app.constants'
import { TweenLite, Power2 } from 'gsap'
import { MetaService } from '../../../common/services/meta.service'

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss'],
  providers: [ResourceService, MetaService]
})
export class PostDetailComponent implements OnInit {
  slug: string
  post: Post
  relatedPosts: Post[]
  storagePath: string = environment.storagePath
  currentUrl = window.location.href
  selectedRelatedPostIndex = 0

  isMobile: boolean

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth < appConstants.TOUCH_BREAKPOINT
  }

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.slug = params.slug

      // Reset post to prevent weird change on moving from a post detail to another post detail
      this.post = null

      this.resourceService
        .showBySlug('posts', this.slug)
        .subscribe((postRes: Post) => {
          this.post = postRes

          let relatedPostParams = new HttpParams()
          relatedPostParams = relatedPostParams.set('limitTo', '6')
          relatedPostParams = relatedPostParams.set(
            'excludePostId',
            this.post.id.toString()
          )
          this.post.categories.forEach((category: Category) => {
            relatedPostParams = relatedPostParams.set(
              'categoryId',
              category.id.toString()
            )
          })
          this.resourceService
            .list('posts', relatedPostParams)
            .subscribe((relatedPostRes: Post[]) => {
              this.relatedPosts = relatedPostRes
            })

          // Update META
          this.metaService.addMetaTags(
            this.post.title + appConstants.META_TITLE_SUFFIX,
            this.post.excerpt,
            'actualites/' + this.post.slug
          )
          this.metaService.setImage(this.post.image.openGraph)
        })

      this.isMobile = window.innerWidth < appConstants.TOUCH_BREAKPOINT
    })
  }

  fadeLeft(element: ElementRef) {
    TweenLite.to(element.nativeElement, 1, {
      width: '0',
      ease: Power2.easeInOut,
      delay: '0.3'
    })
  }

  fadeInFast(element: ElementRef) {
    TweenLite.to(element.nativeElement, 0.5, {
      opacity: '1',
      ease: Power2.easeInOut,
      delay: '0.1'
    })
  }

  fadeIn(element: ElementRef) {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      ease: Power2.easeInOut,
      delay: '1.'
    })
  }
}
