import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef
} from '@angular/core'
import { Subscription } from 'rxjs'
import { EventService } from '../../common/services/event.service'
import { Route } from '@angular/router'
import { TweenLite } from 'gsap'
import { appRoutes } from '../../app.routes'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('serviceMenu', { static: false }) serviceMenuEl: ElementRef
  @ViewChild('menuLayer', { static: false }) menuLayerEl: ElementRef
  @ViewChild('caret', { static: false }) caretEl: ElementRef

  isOpen = false
  isActive = false
  isFade = false
  showServicesMenu: boolean

  currentRoute: Route

  private subscription = new Subscription()

  constructor(
    private renderer: Renderer2,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.eventService.routeChanged.subscribe(routeChanged => {
        // Close menu on changing route
        if (this.isOpen) {
          this.toggleMenu()
        }

        if (this.showServicesMenu) {
          this.showServicesMenu = false
          TweenLite.set(this.serviceMenuEl.nativeElement, {
            className: '+=is-closed'
          })
          TweenLite.set(this.caretEl.nativeElement, {
            rotation: 0
          })
          TweenLite.set(this.serviceMenuEl.nativeElement, {
            top: -52
          })
          TweenLite.set(this.menuLayerEl.nativeElement, {
            height: '100%'
          })
        }

        // Get current route (to build "service menu" on service pages)
        this.currentRoute = appRoutes.find(
          (r: Route) => r.path === routeChanged.url.substr(1)
        )
      })
    )
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen

    if (this.isOpen) {
      this.renderer.addClass(document.querySelector('html'), 'is-clipped')
      if (this.showServicesMenu) {
        this.toggleServiceMenu()
      }
    } else {
      this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
      this.renderer.removeClass(
        document.querySelector('.sub-menu'),
        'is-active'
      )
      this.renderer.removeClass(
        document.querySelector('.container-main'),
        'is-fade'
      )
    }
  }

  toggleSubMenu(): void {
    this.isActive = !this.isActive
    this.isFade = !this.isFade
  }

  toggleServiceMenu(): void {
    if (!this.showServicesMenu) {
      this.showServicesMenu = true
      TweenLite.to(this.serviceMenuEl.nativeElement, 0.25, {
        className: '-=is-closed',
        top: -28
      })

      setTimeout(() => {
        TweenLite.to(this.menuLayerEl.nativeElement, 0.25, {
          height: 0
        })
        TweenLite.to(this.caretEl.nativeElement, 0.25, {
          rotation: -180
        })
      }, 250)
    } else {
      TweenLite.to(this.menuLayerEl.nativeElement, 0.25, {
        height: '100%'
      })
      TweenLite.to(this.caretEl.nativeElement, 0.25, {
        rotation: 0,
        delay: 0.25
      })
      TweenLite.to(this.serviceMenuEl.nativeElement, 0.25, {
        top: -52
      })

      TweenLite.to(this.serviceMenuEl.nativeElement, 0.25, {
        className: '+=is-closed',
        delay: 0.25
      })

      this.showServicesMenu = false
    }
  }
}
