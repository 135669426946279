import { Component, OnInit, ElementRef } from '@angular/core'
import { servicesContent } from './services.content'
import { ImageSet } from '../../common/interfaces/image-set'
import { ActivatedRoute } from '@angular/router'
import { Department } from '../../common/enums/department'
import { environment } from '../../../environments/environment'
import { ResourceService } from '../../common/services/resource.service'
import { Post } from '../../common/interfaces/post'
import { HttpParams } from '@angular/common/http'
import { Study } from '../../common/interfaces/study'
import { TweenLite, Power2 } from 'gsap'
import { MetaService } from '../../common/services/meta.service'

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  providers: [ResourceService, MetaService]
})
export class ServicesComponent implements OnInit {
  service: string
  department: Department
  content: {
    images: ImageSet[]
    body: string
  }
  posts: Post[]
  studies: Study[]

  storagePath: string = environment.storagePath

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.service = this.activatedRoute.snapshot.data['service']
    this.department = this.activatedRoute.snapshot.data['department']

    this.metaService.setTags(this.service)

    // Get static data from ./services.content
    this.content = servicesContent[this.service]

    let postParams = new HttpParams()
    postParams = postParams.set('categoryName', this.department)
    postParams = postParams.set('limitTo', '6')
    this.resourceService
      .list('posts', postParams)
      .subscribe((postRes: Post[]) => {
        this.posts = postRes
      })

    let studyParams = new HttpParams()
    studyParams = studyParams.set('limitTo', '3')
    studyParams = studyParams.set('department', this.department)
    this.resourceService
      .list('studies', studyParams)
      .subscribe((studyRes: Study[]) => {
        this.studies = studyRes
      })
  }

  // fade Animations
  fadeInFast(element: ElementRef) {
    TweenLite.to(element.nativeElement, 0.5, {
      opacity: '1',
      ease: Power2.easeInOut,
      delay: '0.1'
    })
  }

  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
  fadeLeft(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
}
