import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DynamicContent } from '../../common/interfaces/dynamic-content'
import { DynamicContentService } from '../../common/services/dynamic-content.service'
import { Organization } from '../../common/interfaces/organization'
import { HttpParams } from '@angular/common/http'
import { OrganizationType } from '../../common/enums/organization-type'
import { ResourceService } from '../../common/services/resource.service'
import { Testimonial } from '../../common/interfaces/testimonial'
import { TweenLite, TimelineLite, Power2 } from 'gsap'
import { MetaService } from '../../common/services/meta.service'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [ResourceService, MetaService]
})
export class AboutComponent implements OnInit {
  dynamicContent: DynamicContent
  customers: Organization[]
  testimonials: Testimonial[]

  selectedTestimonialIndex: number

  constructor(
    private dynamicContentService: DynamicContentService,
    private resourceService: ResourceService,
    metaService: MetaService
  ) {
    metaService.setTags('about')
  }

  ngOnInit() {
    this.dynamicContentService.dynamicContentSubject.subscribe(
      (dynamicContent: DynamicContent) => {
        this.dynamicContent = dynamicContent
      }
    )

    let organizationParams = new HttpParams()
    organizationParams = organizationParams.set(
      'type',
      OrganizationType.Customer
    )
    organizationParams = organizationParams.set('withoutPaginator', 'true')
    this.resourceService
      .list('organizations', organizationParams)
      .subscribe((customerRes: Organization[]) => {
        this.customers = customerRes
      })

    let testimonialParams = new HttpParams()
    testimonialParams = testimonialParams.set('withoutPaginator', 'true')
    this.resourceService
      .list('testimonials', testimonialParams)
      .subscribe((testimonialRes: Testimonial[]) => {
        this.testimonials = testimonialRes
        if (this.testimonials.length) {
          this.selectedTestimonialIndex = 0
        }
      })
  }

  fadeUp(element: ElementRef) {
    TweenLite.to(element.nativeElement, 1, {
      height: '0',
      ease: Power2.easeInOut,
      delay: '0.3'
    })
  }

  fade(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.to(
      element.nativeElement,
      0.5,
      {
        width: '100%',
        ease: Power2.easeIn
      },
      '+=0.5'
    )
    timeline.set(element.nativeElement, {
      left: 'inherit',
      right: '0'
    })
    timeline.to(element.nativeElement, 0.3, {
      width: '0',
      ease: Power2.easeOut
    })
  }

  appear(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.set(
      element.nativeElement,

      {
        opacity: '1'
      },
      '+=1'
    )
  }
  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
  fadeLeft(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
  fadeIn(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      top: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
}
