import { Directive, ElementRef, Input, OnInit } from '@angular/core'

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {
  private _autofocus

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this._autofocus || typeof this._autofocus === 'undefined') {
      this.elementRef.nativeElement.focus()
    }
  }

  @Input()
  set autofocus(condition: boolean) {
    this._autofocus = condition !== false
  }
}
