import {
  Component,
  Renderer,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  HostListener
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { StudyService } from '../../../common/services/study.service'
import { Paginator } from '../../../common/interfaces/paginator'
import { ActivatedRoute, Router } from '@angular/router'
import { ResourceService } from '../../../common/services/resource.service'
import { Domain } from '../../../common/interfaces/domain'
import { SubDomain } from '../../../common/interfaces/sub-domain'
import { Category } from '../../../common/interfaces/category'
import { TweenLite, Power2, TimelineLite } from 'gsap'
import { MetaService } from '../../../common/services/meta.service'

@Component({
  selector: 'app-study-list',
  templateUrl: './study-list.component.html',
  styleUrls: ['./study-list.component.scss'],
  providers: [StudyService, ResourceService, MetaService]
})
export class StudyListComponent implements OnInit {
  @ViewChild('termsInput', { static: false })
  termsInput: ElementRef
  @ViewChild('studyList', { static: false })
  studyListEl: ElementRef

  studyFilters: FormGroup = this.formBuilder.group({
    terms: ''
  })

  studiesPaginator: Paginator

  years: number[]
  domains: Domain[]
  subDomains: SubDomain[]
  categories: Category[]
  isYearsSelectOpen = false
  isDepartmentsSelectOpen = false
  isDomainsSelectOpen = false
  isSubDomainsSelectOpen = false
  selectedYear: number
  selectedDepartment: string
  selectedDomain: Domain
  selectedSubDomain: SubDomain
  terms: string

  leavingAnimationDuration = 500

  focusSearch = true
  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer,
    private studyService: StudyService,
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private metaService: MetaService
  ) {
    this.metaService.setTags('studyList')
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      // Activate filters
      this.selectedYear = parseInt(queryParams.year, 10)
      this.selectedDepartment = queryParams.department
      this.terms = queryParams.terms

      // Launch animation timeline
      const tl = new TimelineLite()

      delete this.studiesPaginator
      this.loading = true
      this.studyService.list(queryParams).subscribe(res => {
        this.studiesPaginator = res
        this.loading = false

        setTimeout(() => {
          tl.staggerTo(
            this.studyListEl.nativeElement.querySelectorAll('.studies__meta'),
            0.5,
            { opacity: 1 },
            0.1
          )
          tl.staggerTo(
            this.studyListEl.nativeElement.querySelectorAll(
              '.study-card-wrapper'
            ),
            0.5,
            { opacity: 1, top: 0 },
            0.1
          )
        }, 100) // Just enough time form DOM changes
      })

      this.resourceService.list('studies/years').subscribe((res: number[]) => {
        this.years = res
      })
      this.resourceService.list('domains').subscribe((res: Domain[]) => {
        this.domains = res
        this.selectedDomain = this.domains.find(
          d => d.id === parseInt(queryParams.domainId, 10)
        )
      })
      this.resourceService.list('sub-domains').subscribe((res: SubDomain[]) => {
        this.subDomains = res
        this.selectedSubDomain = this.subDomains.find(
          d => d.id === parseInt(queryParams.subDomainId, 10)
        )
      })
      this.resourceService.list('categories').subscribe((res: Category[]) => {
        this.categories = res
      })
    })
  }

  filter({
    year,
    department,
    domainId,
    subDomainId,
    terms,
    reset
  }: {
    year?: number
    department?: string
    domainId?: number
    subDomainId?: number
    terms?: string
    reset?: string
  }) {
    const queryParams: any = { page: 1, terms: terms }

    if (year) {
      queryParams.year = year
    }
    if (department) {
      queryParams.department = department
    }
    if (domainId) {
      queryParams.domainId = domainId
    }
    if (subDomainId) {
      queryParams.subDomainId = subDomainId
    }

    if (reset) {
      queryParams[reset] = ''
    }

    this.router.navigate(['references'], {
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    })
  }
  // fade Animations
  fadeInFast(element: ElementRef) {
    TweenLite.to(element.nativeElement, 0.5, {
      opacity: '1',
      ease: Power2.easeInOut,
      delay: '0.1'
    })
  }

  resetTerms(): void {
    this.studyFilters.controls['terms'].setValue('')
    this.filter({ reset: 'terms' })
    this.renderer.invokeElementMethod(this.termsInput.nativeElement, 'focus')
  }

  @HostListener('click', ['$event.target'])
  onClick(target) {
    // We track clicks to close selects if open and click outside
    if (
      this.isYearsSelectOpen &&
      !this.elementRef.nativeElement
        .querySelector('.year-select')
        .contains(target)
    ) {
      this.isYearsSelectOpen = false
    }
    if (
      this.isDepartmentsSelectOpen &&
      !this.elementRef.nativeElement
        .querySelector('.department-select')
        .contains(target)
    ) {
      this.isDepartmentsSelectOpen = false
    }
    if (
      this.isDomainsSelectOpen &&
      !this.elementRef.nativeElement
        .querySelector('.domain-select')
        .contains(target)
    ) {
      this.isDomainsSelectOpen = false
    }
    if (
      this.isSubDomainsSelectOpen &&
      !this.elementRef.nativeElement
        .querySelector('.sub-domain-select')
        .contains(target)
    ) {
      this.isSubDomainsSelectOpen = false
    }
  }
}
