import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core'
import { Organization } from '../../../common/interfaces/organization'
import { environment } from '../../../../environments/environment'
import { TweenLite, TimelineLite, Power2, Expo } from 'gsap'

@Component({
  selector: 'app-organization-carousel',
  templateUrl: './organization-carousel.component.html',
  styleUrls: ['./organization-carousel.component.scss']
})
export class OrganizationCarouselComponent implements OnInit, AfterViewInit {
  @Input() organizations: Organization[]
  @Input() title = 'Nos partenaires'
  @ViewChild('carousel', { static: false }) carouselEl: ElementRef

  slideEls: ElementRef[]
  organizationSlides: Organization[][] = []
  storagePath: string = environment.storagePath

  selectedSlideIndex = 0

  ngOnInit() {
    this.organizations.forEach((partner: Organization, index: number) => {
      const slideNumber = Math.floor(index / 8)
      if (!this.organizationSlides[slideNumber]) {
        this.organizationSlides[slideNumber] = []
      }

      this.organizationSlides[slideNumber].push(partner)
    })
  }

  ngAfterViewInit() {
    this.slideEls = this.carouselEl.nativeElement.querySelectorAll(
      '.carousel-item'
    )

    // Animation to activate first slide
    if (this.slideEls && this.slideEls.length) {
      TweenLite.to(this.slideEls[0], 0.2, {
        className: '+=is-active'
      })
    }
  }

  goToSlide(slideNumber: number) {
    const timeline = new TimelineLite()

    this.selectedSlideIndex = slideNumber

    // Make active slide disappear
    const activeSlide = this.carouselEl.nativeElement.querySelector(
      '.carousel-item.is-active'
    )
    timeline.to(activeSlide, 0.2, {
      className: '-=is-active',
      ease: Power2.easeIn
    })
    timeline.set(activeSlide, {
      display: 'none'
    })

    // Make new slide appear
    const newSlide = this.carouselEl.nativeElement.querySelector(
      `.carousel-item.index-${slideNumber}`
    )
    timeline.set(newSlide, {
      display: 'block'
    })
    timeline.to(
      newSlide,
      0.3,
      {
        className: '+=is-active',
        ease: Expo.easeInOut
      },
      '-=0.05'
    )
  }
  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
  fadeLeft(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
}
