import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  AfterViewInit
} from '@angular/core'
import { TweenLite, TimelineLite, Expo, Power2 } from 'gsap'
import { DynamicContent } from '../../common/interfaces/dynamic-content'
import { teamCarouselImages } from './team-carousel-images.content'
import { appConstants } from '../../app.constants'

@Component({
  selector: 'app-team-carousel',
  templateUrl: './team-carousel.component.html',
  styleUrls: ['./team-carousel.component.scss']
})
export class TeamCarouselComponent implements OnInit, AfterViewInit {
  @Input() dynamicContent: DynamicContent
  @ViewChild('carousel', { static: false }) carouselEl: ElementRef
  @ViewChild('arrowsWrapper', { static: false }) arrowsWrapperEl: ElementRef

  selectedTeamSlideIndex = 0
  images: { portraitRetina: string; portraitMobile: string }[]

  interval: any

  ngOnInit() {
    this.images = teamCarouselImages

    if (this.interval) {
      clearInterval(this.interval)
    }
    this.interval = setInterval(() => {
      if (this.selectedTeamSlideIndex < this.images.length - 1) {
        this.selectedTeamSlideIndex++
      } else {
        this.selectedTeamSlideIndex = 0
      }
      this.goToSlide(this.selectedTeamSlideIndex)
    }, appConstants.HOME_TEAM_SLIDER_INTERVAL)
  }

  ngAfterViewInit() {
    // Make first slide appear
    const newSlide = this.carouselEl.nativeElement.querySelector(
      `.carousel-item.index-${this.selectedTeamSlideIndex}`
    )
    const newSlideImg = this.carouselEl.nativeElement.querySelector(
      `.carousel-item.index-${this.selectedTeamSlideIndex} img`
    )
    TweenLite.set(newSlide, {
      className: '+=is-active',
      left: 0
    })
    TweenLite.set(newSlideImg, {
      scale: 1,
      width: '100%'
    })
  }

  fadeLeft(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.25,
      ease: Power2.easeInOut
    })
  }

  goToSlide(slideNumber: number) {
    this.selectedTeamSlideIndex = slideNumber

    const timeline = new TimelineLite()

    // Make active slide disappear
    const timelineArrows = new TimelineLite()

    timelineArrows.set(this.arrowsWrapperEl.nativeElement, {
      className: '+=no-event'
    })

    const activeSlide = this.carouselEl.nativeElement.querySelector(
      '.carousel-item.is-active'
    )
    timeline.to(activeSlide, 0.85, {
      className: '-=is-active',
      left: '-100%',
      ease: Expo.easeOut
    })
    timeline.set(activeSlide, {
      left: '100%'
    })
    timeline.to(
      this.carouselEl.nativeElement.querySelector(
        `.carousel-item.index-${slideNumber} img`
      ),
      0.85,
      {
        scale: 1.1
      },
      '-=0.85'
    )

    // Make new slide appear

    const newSlide = this.carouselEl.nativeElement.querySelector(
      `.carousel-item.index-${slideNumber}`
    )
    timeline.to(
      newSlide,
      0.85,
      {
        className: '+=is-active',
        left: 0,
        ease: Expo.easeOut
      },
      '-=0.85'
    )
    timeline.fromTo(
      this.carouselEl.nativeElement.querySelector(
        `.carousel-item.index-${slideNumber} img`
      ),
      0.85,
      {
        scale: 1.1
      },
      {
        scale: 1
      },
      '-=0.85'
    )
    timelineArrows.set(
      this.arrowsWrapperEl.nativeElement,
      {
        className: '-=no-event'
      },
      '+=0.85'
    )
  }
}
