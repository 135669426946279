import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppComponent } from './app.component'
import { HomeComponent } from './pages/home/home.component'
import { HeaderComponent } from './partials/header/header.component'
import { FooterComponent } from './partials/footer/footer.component'
import { Error404Component } from './errors/error404/error404.component'
import { appRoutes } from './app.routes'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { ContactComponent } from './pages/contact/contact.component'
import { AboutComponent } from './pages/about/about.component'
import { TeamComponent } from './pages/team/team.component'
import { PostListComponent } from './resources/posts/post-list/post-list.component'
import { PostCardComponent } from './resources/posts/post-card/post-card.component'
import { PostDetailComponent } from './resources/posts/post-detail/post-detail.component'
import { StudyCardComponent } from './resources/studies/study-card/study-card.component'
import { StudyListComponent } from './resources/studies/study-list/study-list.component'
import { AutofocusDirective } from './common/directives/autofocus.directive'
import { PaginationComponent } from './partials/pagination/pagination.component'
import { EventService } from './common/services/event.service'
import { FlashMessagesModule } from 'angular2-flash-messages'
import { DynamicContentService } from './common/services/dynamic-content.service'
import { OrganizationCarouselComponent } from './resources/organizations/organization-carousel/organization-carousel.component'
import { JobOfferCardComponent } from './resources/job-offers/job-offer-card/job-offer-card.component'
import { JobOfferListComponent } from './resources/job-offers/job-offer-list/job-offer-list.component'
import { JobOfferDetailComponent } from './resources/job-offers/job-offer-detail/job-offer-detail.component'
import { LegalTermsComponent } from './pages/legal-terms/legal-terms.component'
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component'

import { RecaptchaModule } from 'ng-recaptcha'
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component'
import { ServicesComponent } from './pages/services/services.component'
import { TruncatePipe } from './common/pipes/truncate.pipe'
import { environment } from '../environments/environment'
import { EmployeeCardComponent } from './resources/employees/employee-card/employee-card.component'
import { ViewportEnterDirective } from './common/directives/viewport-enter.directive'
import { DelayedRouterLinkDirective } from './common/directives/delayed-router-link.directive'
import { CookieModalComponent } from './partials/cookie-modal/cookie-modal.component'
import { SlideComponent } from './resources/slides/slide/slide.component'
import { PostCarouselComponent } from './resources/posts/post-carousel/post-carousel.component'
import { JobOfferCarouselComponent } from './resources/job-offers/job-offer-carousel/job-offer-carousel.component'
import { TeamCarouselComponent } from './partials/team-carousel/team-carousel.component'
import { TestimonialCarouselComponent } from './resources/testimonials/testimonial-carousel/testimonial-carousel.component'
import { CookieService } from 'ngx-cookie-service'

import { VgCoreModule } from 'videogular2/core'
import { VgControlsModule } from 'videogular2/controls'
import { VgOverlayPlayModule } from 'videogular2/overlay-play'
import { VgBufferingModule } from 'videogular2/buffering'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Error404Component,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    AboutComponent,
    TeamComponent,
    PostListComponent,
    PostCardComponent,
    PostDetailComponent,
    StudyCardComponent,
    StudyListComponent,
    AutofocusDirective,
    PaginationComponent,
    OrganizationCarouselComponent,
    JobOfferCardComponent,
    JobOfferListComponent,
    JobOfferDetailComponent,
    LegalTermsComponent,
    PrivacyPolicyComponent,
    UnsubscribeComponent,
    ServicesComponent,
    TruncatePipe,
    EmployeeCardComponent,
    ViewportEnterDirective,
    DelayedRouterLinkDirective,
    CookieModalComponent,
    SlideComponent,
    PostCarouselComponent,
    JobOfferCarouselComponent,
    TeamCarouselComponent,
    TestimonialCarouselComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    ReactiveFormsModule,
    HttpClientModule,
    FlashMessagesModule.forRoot(),
    RecaptchaModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [EventService, DynamicContentService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {}
