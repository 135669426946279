import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core'
import { ResourceService } from '../../common/services/resource.service'
import { Employee } from '../../common/interfaces/employee'
import { HttpParams } from '@angular/common/http'
import { DynamicContentService } from '../../common/services/dynamic-content.service'
import { DynamicContent } from '../../common/interfaces/dynamic-content'
import { environment } from '../../../environments/environment'
import { TweenLite, TimelineLite, Power2 } from 'gsap'
import { MetaService } from '../../common/services/meta.service'

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [ResourceService, MetaService]
})
export class TeamComponent implements OnInit {
  @ViewChild('employeeModal', { static: false }) employeeModalEl: ElementRef
  @ViewChild('employeeModalBg', { static: false }) employeeModalBgEl: ElementRef
  @ViewChild('employeeModalFg', { static: false }) employeeModalFgEl: ElementRef
  @ViewChild('figureBorder', { static: false }) figureBorderEl: ElementRef
  @ViewChild('employeeTitle', { static: false }) employeeTitleEl: ElementRef
  @ViewChild('employeeSubtitle', { static: false })
  employeeSubtitleEl: ElementRef
  @ViewChild('employeeDescription', { static: false })
  employeeDescriptionEl: ElementRef
  @ViewChild('modalImage', { static: false }) set modalImage(
    modalImage: ElementRef
  ) {
    this.modalImageEl = modalImage
  }

  private modalImageEl: ElementRef

  dynamicContent: DynamicContent
  employees: Employee[]
  highlightedEmployee: Employee

  storagePath: string = environment.storagePath

  constructor(
    private resourceService: ResourceService,
    private dynamicContentService: DynamicContentService,
    private metaService: MetaService,
    private renderer: Renderer2
  ) {
    this.metaService.setTags('team')
  }

  ngOnInit(): void {
    this.dynamicContentService.dynamicContentSubject.subscribe(
      (dynamicContent: DynamicContent) => {
        this.dynamicContent = dynamicContent

        if (this.dynamicContent.teamImage) {
          this.metaService.setImage(this.dynamicContent.teamImage.openGraph)
        }
      }
    )

    let params = new HttpParams()
    params = params.set('withoutPaginator', 'true')
    this.resourceService
      .list('employees', params)
      .subscribe((employeeRes: Employee[]) => {
        this.employees = employeeRes
      })
  }

  fade(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.to(
      element.nativeElement,
      0.8,
      {
        width: '100%',
        ease: Power2.easeOut
      },
      '+=0.3'
    )
    timeline.to(
      element.nativeElement,
      0.5,
      {
        width: '0',
        ease: Power2.easeIn
      },
      '+=0.8'
    )
  }

  appear(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.set(
      element.nativeElement,

      {
        opacity: '1'
      },
      '+=1'
    )
  }
  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }

  highlightEmployee(employee: Employee): void {
    this.highlightedEmployee = employee
    this.renderer.addClass(document.querySelector('html'), 'is-clipped')
    this.highlightEmployeeAnimation()
  }

  closeHighlightedEmployee(): void {
    delete this.highlightEmployee
    this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
    this.closeHighlightedEmployeeAnimation()
  }

  highlightEmployeeAnimation() {
    // Fade in modal
    TweenLite.to(this.employeeModalEl.nativeElement, 0.5, {
      display: 'block',
      visibility: 'visible',
      opacity: 1
    })

    // Fade in employee picture after modal is faded
    setTimeout(() => {
      TweenLite.to(this.modalImageEl.nativeElement, 0.25, {
        width: 0
      })
      // Width Blue Bg Animation
      TweenLite.to(this.employeeModalBgEl.nativeElement, 0.25, {
        className: '+=has-width'
      })
      TweenLite.to(this.employeeModalFgEl.nativeElement, 0.25, {
        className: '+=has-width'
      })
    }, 800)

    // Border Animation
    setTimeout(() => {
      TweenLite.to(this.figureBorderEl.nativeElement, 0.25, {
        opacity: 1
      })

      // Text Animations
      TweenLite.to(this.employeeTitleEl.nativeElement, 0.5, {
        opacity: 1
      })
      TweenLite.to(this.employeeSubtitleEl.nativeElement, 0.5, {
        opacity: 1
      })
      TweenLite.to(this.employeeDescriptionEl.nativeElement, 0.5, {
        opacity: 1
      })
    }, 1105)
  }

  closeHighlightedEmployeeAnimation() {
    // Fadeout image
    TweenLite.to(this.modalImageEl.nativeElement, 0.25, {
      width: '100%'
    })

    // BG Animation get out
    TweenLite.to(this.employeeModalBgEl.nativeElement, 0.25, {
      className: '-=has-width'
    })
    TweenLite.to(this.employeeModalFgEl.nativeElement, 0.25, {
      className: '-=has-width'
    })

    // Border Animations
    TweenLite.to(this.figureBorderEl.nativeElement, 0.25, {
      opacity: 0
    })

    // Text Animations
    TweenLite.to(this.employeeTitleEl.nativeElement, 0.5, {
      opacity: 0
    })
    TweenLite.to(this.employeeSubtitleEl.nativeElement, 0.5, {
      opacity: 0
    })
    TweenLite.to(this.employeeDescriptionEl.nativeElement, 0.5, {
      opacity: 0
    })

    // Fadeout modal element after 2 seconds
    TweenLite.to(this.employeeModalEl.nativeElement, 0.5, {
      opacity: 0,
      delay: 0.5
    })
    TweenLite.set(this.employeeModalEl.nativeElement, {
      display: 'none',
      visibility: 'hidden',
      delay: 1
    })
  }
}
