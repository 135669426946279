import {
  Component,
  Input,
  OnInit,
  HostListener,
  PLATFORM_ID,
  Inject
} from '@angular/core'
import { Post } from '../../../common/interfaces/post'
import { PostLayout } from '../../../common/enums/post-layout'
import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent implements OnInit {
  @Input() post: Post
  @Input() layout: PostLayout

  titleMaxLength: number
  resumeMaxLength: number

  storagePath: string = environment.storagePath

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  @HostListener('window:resize', [])
  onResize() {
    this.truncateContent()
  }

  ngOnInit() {
    this.truncateContent()
  }

  truncateContent(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth >= appConstants.TOUCH_BREAKPOINT) {
        switch (this.layout) {
          case 'LARGE':
            this.titleMaxLength = 170
            this.resumeMaxLength = 100
            break
          case 'STANDARD':
            this.titleMaxLength = 999
            this.resumeMaxLength = 110
            break
          case 'THUMBNAIL':
            this.titleMaxLength = 46
            this.resumeMaxLength = 80
            break
          case 'MINIMALIST':
            this.titleMaxLength = 46
            this.resumeMaxLength = 80
            break
          default:
            this.titleMaxLength = 999
            this.resumeMaxLength = 999
            break
        }
      }
    }
  }
}
