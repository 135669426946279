import {
  Directive,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID
} from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

@Directive({
  selector: '[appViewportEnter]'
})
export class ViewportEnterDirective implements AfterViewInit {
  @Output() appViewportEnter: EventEmitter<any> = new EventEmitter()

  private intersectionObserver?: IntersectionObserver

  constructor(
    private element: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  public ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver(entries => {
        this.checkForIntersection(entries)
      }, {})
      this.intersectionObserver.observe(<Element>this.element.nativeElement)
    }
  }

  private checkForIntersection = (
    entries: Array<IntersectionObserverEntry>
  ) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        this.appViewportEnter.emit(this.element)
        this.intersectionObserver.unobserve(<Element>this.element.nativeElement)
        this.intersectionObserver.disconnect()
      }
    })
  }

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return (
      (<any>entry).isIntersecting && entry.target === this.element.nativeElement
    )
  }
}
