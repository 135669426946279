import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  PLATFORM_ID,
  Inject
} from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute } from '@angular/router'
import { JobOffer } from '../../../common/interfaces/job-offer'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { HTMLInputEvent } from '../../../common/interfaces/html-input-element'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'
import { MetaService } from '../../../common/services/meta.service'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-job-offer-detail',
  templateUrl: './job-offer-detail.component.html',
  styleUrls: ['./job-offer-detail.component.scss'],
  providers: [ResourceService, MetaService]
})
export class JobOfferDetailComponent implements OnInit {
  @ViewChild('resumeInput', { static: false })
  resumeInputEl: ElementRef
  @ViewChild('coverLetterInput', { static: false })
  coverLetterInputEl: ElementRef

  slug: string
  jobOffer: JobOffer

  loading: boolean

  jobOfferForm: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phone: '',
    email: ['', Validators.email],
    message: '',
    resume: [null, Validators.required],
    coverLetter: null,
    consentGDPR: [false, Validators.requiredTrue],
    captcha: [false, Validators.requiredTrue]
  })

  resume: { name: string; content: any }
  coverLetter: { name: string; content: any }

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private flashMessagesService: FlashMessagesService,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit() {
    this.slug = this.activatedRoute.snapshot.params.slug

    this.resourceService
      .showBySlug('job-offers', this.slug)
      .subscribe((jobOfferRes: JobOffer) => {
        this.jobOffer = jobOfferRes

        // Update META
        this.metaService.addMetaTags(
          this.jobOffer.title + appConstants.META_TITLE_SUFFIX,
          this.jobOffer.introduction,
          'offres-d-emploi/' + this.jobOffer.slug
        )
      })
  }

  submitForm() {
    const formData = new FormData()
    formData.append('firstName', this.jobOfferForm.value['firstName'])
    formData.append('lastName', this.jobOfferForm.value['lastName'])
    formData.append('resume', this.resume.content, this.resume.name)

    // Optional
    if (this.coverLetter) {
      formData.append(
        'coverLetter',
        this.coverLetter.content,
        this.coverLetter.name
      )
    }
    if (this.jobOfferForm.value['email']) {
      formData.append('email', this.jobOfferForm.value['email'])
    }
    if (this.jobOfferForm.value['phone']) {
      formData.append('phone', this.jobOfferForm.value['phone'])
    }
    if (this.jobOfferForm.value['message']) {
      formData.append('message', this.jobOfferForm.value['message'])
    }

    this.loading = true
    this.resourceService
      .store(`job-offers/${this.slug}/apply`, formData)
      .subscribe(
        res => {
          this.loading = false
          this.jobOfferForm.reset()
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }
          this.flashMessagesService.show(
            `Votre candidature a bien été envoyée`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        },
        err => {
          this.loading = false
          this.flashMessagesService.show(
            'Une erreur est survenue. Veuillez essayer de nous contacter par un autre biais.',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
  }

  captchaResolved(): void {
    this.jobOfferForm.controls['captcha'].setValue(true)
  }

  scrollToForm(): void {
    const element = this.elementRef.nativeElement.querySelector(
      '#job-offer-form-container'
    )
    if (element && isPlatformBrowser(this.platformId)) {
      window.scrollTo({
        behavior: 'smooth',
        top: this.getElementOffset(element)
      })
    }
  }

  getElementOffset(element: any): number {
    let offset = 0

    while (element) {
      offset += element.offsetTop - element.scrollTop + element.clientTop
      element = element.offsetParent
    }

    return offset
  }

  // Triggers on adding resume
  fileInputEvent(fileInput: HTMLInputEvent, inputName: string) {
    if (inputName === 'resume') {
      this.resume = {
        name: fileInput.target.files[0].name,
        content: this.resumeInputEl.nativeElement.files.item(0)
      }
    } else if (inputName === 'coverLetter') {
      this.coverLetter = {
        name: fileInput.target.files[0].name,
        content: this.coverLetterInputEl.nativeElement.files.item(0)
      }
    }
  }
}
