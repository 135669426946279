import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild
} from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { JobOffer } from '../../../common/interfaces/job-offer'
import { HttpParams } from '@angular/common/http'
import { DynamicContent } from '../../../common/interfaces/dynamic-content'
import { DynamicContentService } from '../../../common/services/dynamic-content.service'
import { environment } from '../../../../environments/environment'
import { ContractType } from '../../../common/enums/contract-type'
import { Department } from '../../../common/enums/department'
import { TweenLite, TimelineLite, Power2, TweenMax } from 'gsap'
import { MetaService } from '../../../common/services/meta.service'

@Component({
  selector: 'app-job-offer-list',
  templateUrl: './job-offer-list.component.html',
  styleUrls: ['./job-offer-list.component.scss'],
  providers: [ResourceService, MetaService]
})
export class JobOfferListComponent implements OnInit {
  @ViewChild('jobOfferList', { static: false }) jobOfferListEl: ElementRef
  jobOffers: JobOffer[]
  dynamicContent: DynamicContent
  storagePath: string = environment.storagePath

  isContractTypeOpen: boolean
  isDepartmentOpen: boolean
  selectedContractType: ContractType
  selectedDepartment: Department

  Department = Department
  ContractType = ContractType

  loadingJobOffers: boolean

  constructor(
    private resourceService: ResourceService,
    private dynamicContentService: DynamicContentService,
    private elementRef: ElementRef,
    private metaService: MetaService
  ) {
    this.metaService.setTags('jobOfferList')
  }

  ngOnInit() {
    this.dynamicContentService.dynamicContentSubject.subscribe(
      (dynamicContent: DynamicContent) => {
        this.dynamicContent = dynamicContent
      }
    )

    let httpParams = new HttpParams()
    httpParams = httpParams.set('withoutPaginator', 'true')
    this.resourceService
      .list('job-offers', httpParams)
      .subscribe((jobOfferRes: JobOffer[]) => {
        this.jobOffers = jobOfferRes
        setTimeout(() => {
          this.staggerCards(this.jobOfferListEl)
        }, 100)
      })
  }

  filter({
    contractType,
    department,
    resetContractType,
    resetDepartment
  }: {
    contractType?: ContractType
    department?: Department
    resetContractType?: boolean
    resetDepartment?: boolean
  }): void {
    this.loadingJobOffers = true
    this.jobOffers = []

    if (resetContractType) {
      this.selectedContractType = null
    } else {
      this.selectedContractType = contractType
        ? contractType
        : this.selectedContractType
    }
    if (resetDepartment) {
      this.selectedDepartment = null
    } else {
      this.selectedDepartment = department
        ? department
        : this.selectedDepartment
    }

    let httpParams = new HttpParams()
    httpParams = httpParams.set('withoutPaginator', 'true')

    if (this.selectedContractType) {
      httpParams = httpParams.set('contractType', this.selectedContractType)
    }

    if (this.selectedDepartment) {
      httpParams = httpParams.set('department', this.selectedDepartment)
    }

    this.resourceService
      .list('job-offers', httpParams)
      .subscribe((jobOfferRes: JobOffer[]) => {
        this.loadingJobOffers = false
        this.jobOffers = jobOfferRes
        setTimeout(() => {
          this.staggerCards(this.jobOfferListEl)
        }, 100)
      })
  }

  fadeUp(element: ElementRef) {
    TweenLite.to(element.nativeElement, 1, {
      height: '0',
      ease: Power2.easeInOut,
      delay: '0.3'
    })
  }

  fade(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.to(
      element.nativeElement,
      0.5,
      {
        width: '100%',
        ease: Power2.easeIn
      },
      '+=0.5'
    )
    timeline.set(element.nativeElement, {
      left: 'inherit',
      right: '0'
    })
    timeline.to(element.nativeElement, 0.3, {
      width: '0',
      ease: Power2.easeOut
    })
  }

  appear(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.set(
      element.nativeElement,

      {
        opacity: '1'
      },
      '+=1'
    )
  }
  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }

  @HostListener('click', ['$event.target'])
  onClick(target) {
    // We track clicks to close selects if open and click outside
    if (
      this.isContractTypeOpen &&
      !this.elementRef.nativeElement
        .querySelector('.contract-type-select')
        .contains(target)
    ) {
      this.isContractTypeOpen = false
    }
    if (
      this.isDepartmentOpen &&
      !this.elementRef.nativeElement
        .querySelector('.department-select')
        .contains(target)
    ) {
      this.isDepartmentOpen = false
    }
  }

  staggerCards(element: ElementRef) {
    TweenMax.staggerTo(
      element.nativeElement.querySelectorAll('.job-card-wrapper'),
      0.5,
      { left: 0, opacity: 1 },
      0.2
    )
  }
}
