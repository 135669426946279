import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  PLATFORM_ID,
  Inject
} from '@angular/core'
import { TweenLite } from 'gsap'
import { CookieService } from 'ngx-cookie-service'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent implements OnInit {
  @ViewChild('modal', { static: false }) modalEl: ElementRef
  showModal: boolean

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.showModal = !this.cookieService.get('accept_cookies')

      if (!window['dataLayer']) {
        window['dataLayer'] = []
      }
    }
  }

  acceptCookies(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set('accept_cookies', 'true')

      window['dataLayer'].push({
        event: 'launch GA'
      })

      TweenLite.to(this.modalEl.nativeElement, 1, {
        top: 500,
        opacity: 0
      })
      setTimeout(() => {
        this.showModal = false
      }, 1000)
    }
  }
}
