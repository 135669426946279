import { Component, OnInit, ElementRef } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { HttpParams } from '@angular/common/http'
import { Paginator } from '../../../common/interfaces/paginator'
import { Category } from '../../../common/interfaces/category'
import { Post } from '../../../common/interfaces/post'
import { environment } from '../../../../environments/environment'
import { TweenLite, TimelineLite, Power2, TweenMax } from 'gsap'
import { MetaService } from '../../../common/services/meta.service'

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss'],
  providers: [ResourceService, MetaService]
})
export class PostListComponent implements OnInit {
  page: number
  categoryId: number
  categories: Category[]

  // Posts are grouped for layout
  paginator: Paginator
  posts: Post[]

  storagePath: string = environment.storagePath

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private metaService: MetaService
  ) {
    this.metaService.setTags('postList')
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.page = parseInt(queryParams.page, 10) || 1
      this.categoryId = parseInt(queryParams.categoryId, 10) || null

      let params = new HttpParams()
      params = params.set('page', this.page.toString())
      if (queryParams.categoryId) {
        params = params.set('categoryId', queryParams.categoryId)
      }

      this.resourceService
        .list('posts', params)
        .subscribe((postRes: Paginator) => {
          this.paginator = postRes
          this.posts = postRes.data

          if (this.posts.length) {
            this.metaService.setImage(this.posts[0].image.openGraph)
          }
        })

      this.resourceService
        .list('categories')
        .subscribe((categoryRes: Category[]) => {
          // Order Categories by post count
          this.categories = categoryRes.sort((a: Category, b: Category) => {
            if (a.posts.length < b.posts.length) {
              return 1
            } else if (a.posts.length > b.posts.length) {
              return -1
            } else {
              return 0
            }
          })
        })
    })
  }

  fade(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.to(
      element.nativeElement,
      0.5,
      {
        width: '100%',
        ease: Power2.easeIn
      },
      '+=0.5'
    )
    timeline.set(element.nativeElement, {
      left: 'inherit',
      right: '0'
    })
    timeline.to(element.nativeElement, 0.3, {
      width: '0',
      ease: Power2.easeOut
    })
  }

  appear(element: ElementRef) {
    const timeline = new TimelineLite()
    timeline.set(
      element.nativeElement,

      {
        opacity: '1'
      },
      '+=1'
    )
  }
  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.1,
      ease: Power2.easeInOut
    })
  }
  fadeUp(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      top: '0',
      delay: 0.1,

      ease: Power2.easeInOut
    })
  }

  filterByCategory(categoryId: number): void {
    this.router.navigate(['/actualites'], {
      queryParams: { categoryId: categoryId }
    })
  }
}
