import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core'
import { Slide } from '../../../common/interfaces/slide'
import { environment } from '../../../../environments/environment'
import { TimelineLite, TweenLite, Power2 } from 'gsap'

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  @Input() slide: Slide
  @Input() defaultActivated: boolean
  @ViewChild('carouselItem', { static: true }) carouselItemEl: ElementRef
  @ViewChild('coverMedia', { static: true }) coverMediaEl: ElementRef
  @ViewChild('coverFigure', { static: true }) coverFigureEl: ElementRef
  @ViewChild('coverContent', { static: true }) coverContentEl: ElementRef

  storagePath: string = environment.storagePath

  ngOnInit() {
    if (this.defaultActivated) {
      this.appear()
    } else {
      TweenLite.set(this.coverMediaEl.nativeElement, {
        xPercent: '100'
      })
      TweenLite.set(this.coverFigureEl.nativeElement, {
        xPercent: '-100'
      })
    }
  }

  public appear() {
    const timeline = new TimelineLite()

    timeline.set(this.carouselItemEl.nativeElement, {
      className: '+=is-active'
    })
    timeline.set(this.coverContentEl.nativeElement, {
      y: '30'
    })
    timeline.set(this.coverMediaEl.nativeElement, {
      xPercent: '-100'
    })
    timeline.set(this.coverFigureEl.nativeElement, {
      xPercent: '100'
    })
    timeline.to(
      this.coverContentEl.nativeElement,
      0.2,
      {
        opacity: 1,
        y: '0',
        ease: Power2.easeOut
      },
      '+=0.2'
    )
    timeline.to(
      this.coverMediaEl.nativeElement,
      0.8,
      {
        xPercent: '0',
        ease: Power2.easeInOut
      },
      '-=0.4'
    )
    timeline.to(
      this.coverFigureEl.nativeElement,
      0.8,
      {
        xPercent: '0',
        ease: Power2.easeInOut
      },
      '-=0.8'
    )
  }

  disappear(): void {
    const timeline = new TimelineLite()

    timeline.set(this.carouselItemEl.nativeElement, {
      className: '-=is-active'
    })

    timeline.to(this.coverContentEl.nativeElement, 0.2, {
      opacity: 0,
      y: '-30',
      ease: Power2.easeIn
    })

    timeline.to(
      this.coverMediaEl.nativeElement,
      0.2,
      {
        xPercent: '100'
      },
      '+=1'
    ),
      timeline.to(
        this.coverFigureEl.nativeElement,
        0.2,
        {
          xPercent: '-100'
        },
        '-=0.2'
      )
  }
}
