import { Component, PLATFORM_ID, Inject } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ResourceService } from '../../common/services/resource.service'
import { Subscriber } from '../../common/interfaces/subscriber'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../app.constants'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [ResourceService]
})
export class FooterComponent {
  newsletterForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    acceptLegalTerms: [false, Validators.requiredTrue]
  })
  loading: boolean

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private flashMessagesService: FlashMessagesService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  subscribeToNewsletter(): void {
    const formData = new FormData()
    formData.append('email', this.newsletterForm.value['email'])
    formData.append('lang', 'fr')

    this.resourceService.store('subscribers', formData).subscribe(
      (subscriberRes: Subscriber) => {
        this.loading = false
        this.newsletterForm.reset()
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        this.flashMessagesService.show(
          `Vous êtes bien inscrit à la newsletter Grahal`,
          {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
      },
      err => {
        this.loading = false
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        this.flashMessagesService.show(
          'Une erreur est survenue. Veuillez essayer de nous contacter par un autre biais.',
          {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
      }
    )
  }
}
