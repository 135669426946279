import { Component, Input } from '@angular/core'
import { JobOffer } from '../../../common/interfaces/job-offer'

@Component({
  selector: 'app-job-offer-card',
  templateUrl: './job-offer-card.component.html',
  styleUrls: ['./job-offer-card.component.scss']
})
export class JobOfferCardComponent {
  @Input() jobOffer: JobOffer
}
