import { Component, OnInit } from '@angular/core'
import { DynamicContentService } from '../../common/services/dynamic-content.service'
import { DynamicContent } from '../../common/interfaces/dynamic-content'
import { MetaService } from '../../common/services/meta.service'

@Component({
  selector: 'app-legal-terms',
  templateUrl: './legal-terms.component.html',
  styleUrls: ['./legal-terms.component.scss'],
  providers: [MetaService]
})
export class LegalTermsComponent implements OnInit {
  dynamicContent: DynamicContent
  constructor(
    private dynamicContentService: DynamicContentService,
    private metaService: MetaService
  ) {
    this.metaService.setTags('legalTerms')
  }

  ngOnInit() {
    this.dynamicContentService.dynamicContentSubject.subscribe(
      (dynamicContent: DynamicContent) => {
        this.dynamicContent = dynamicContent
      }
    )
  }
}
