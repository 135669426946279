export const appConstants = {
  // Time delays and durations
  FLASH_MESSAGE_TIMEOUT: 4000,
  ROUTE_CHANGE_DELAY: 750,
  VIEW_INTRODUCTION_DELAY: 750,
  HOME_SLIDER_INTERVAL: 5000,
  HOME_TEAM_SLIDER_INTERVAL: 4000,

  // Breakpoints
  MOBILE_BREAKPOINT: 396,
  TOUCH_BREAKPOINT: 768,
  DESKTOP_BREAKPOINT: 1004,
  WIDESCREEN_BREAKPOINT: 1208,
  FULL_HD_BREAKPOINT: 1496,

  // Other
  META_TITLE_SUFFIX: ' | GRAHAL'
}
