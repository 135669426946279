import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit
} from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ResourceService } from '../../common/services/resource.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../app.constants'
import { HTMLInputEvent } from '../../common/interfaces/html-input-element'
import { DynamicContentService } from '../../common/services/dynamic-content.service'
import { DynamicContent } from '../../common/interfaces/dynamic-content'
import { TweenLite } from 'gsap'
import { MetaService } from '../../common/services/meta.service'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ResourceService, MetaService]
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('attachmentInput', { static: false })
  attachmentInputEl: ElementRef
  @ViewChild('fadeDown', { static: false }) fadeDownEl: ElementRef

  contactForm: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    attachment: [null],
    company: [''],
    phone: [''],
    subject: [''],
    message: [''],
    captcha: [false, Validators.requiredTrue],
    acceptLegalTerms: [false, Validators.requiredTrue]
  })
  attachment: { name: string; content: any }
  dynamicContent: DynamicContent
  loading: boolean

  constructor(
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private dynamicContentService: DynamicContentService,
    private flashMessagesService: FlashMessagesService,
    private metaService: MetaService
  ) {
    this.metaService.setTags('contact')
  }

  ngOnInit(): void {
    this.dynamicContentService.dynamicContentSubject.subscribe(
      (dynamicContent: DynamicContent) => {
        this.dynamicContent = dynamicContent
      }
    )
  }

  ngAfterViewInit(): void {
    TweenLite.to(this.fadeDownEl.nativeElement, 1, {
      opacity: 1,
      top: '32px',
      delay: '0.5'
    })
  }

  submitForm(): void {
    this.loading = true
    const formData = new FormData()
    formData.append('name', this.contactForm.value['name'])
    formData.append('email', this.contactForm.value['email'])

    // Optional
    if (this.contactForm.value['company']) {
      formData.append('company', this.contactForm.value['company'])
    }
    if (this.contactForm.value['phone']) {
      formData.append('phone', this.contactForm.value['phone'])
    }
    if (this.contactForm.value['subject']) {
      formData.append('subject', this.contactForm.value['subject'])
    }
    if (this.contactForm.value['message']) {
      formData.append('message', this.contactForm.value['message'])
    }

    if (this.attachment) {
      formData.append(
        'attachment',
        this.attachment.content,
        this.attachment.name
      )
    }

    this.resourceService.store('contact', formData).subscribe(
      res => {
        this.loading = false
        this.contactForm.reset()
        if (isPlatformBrowser) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.flashMessagesService.show(`Votre message a bien été envoyé`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
        }
      },
      err => {
        this.loading = false
        if (isPlatformBrowser) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.flashMessagesService.show(
            'Une erreur est survenue. Veuillez essayer de nous contacter par un autre biais.',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      }
    )
  }

  attachmentInputEvent(fileInput: HTMLInputEvent) {
    this.attachment = {
      name: fileInput.target.files[0].name,
      content: this.attachmentInputEl.nativeElement.files.item(0)
    }
  }

  captchaResolved(): void {
    this.contactForm.controls['captcha'].setValue(true)
  }
}
