import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  ViewChild,
  ViewChildren,
  QueryList,
  PLATFORM_ID,
  Inject
} from "@angular/core";
import { ResourceService } from "../../common/services/resource.service";
import { Slide } from "../../common/interfaces/slide";
import { environment } from "../../../environments/environment";
import { DynamicContentService } from "../../common/services/dynamic-content.service";
import { DynamicContent } from "../../common/interfaces/dynamic-content";
import { HttpParams } from "@angular/common/http";
import { OrganizationType } from "../../common/enums/organization-type";
import { Organization } from "../../common/interfaces/organization";
import { Post } from "../../common/interfaces/post";
import { JobOffer } from "../../common/interfaces/job-offer";

import { TweenLite, TimelineLite, Power2 } from "gsap";
import { appConstants } from "../../app.constants";
import { SlideComponent } from "../../resources/slides/slide/slide.component";
import { VgAPI } from "videogular2/core";
import { MetaService } from "../../common/services/meta.service";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [ResourceService, MetaService]
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChildren(SlideComponent) slideEls: QueryList<SlideComponent>;
  @ViewChild("greyLayer", { static: true }) greyLayerEl: ElementRef;
  @ViewChild("videoModal1", { static: false }) videoModal1El: ElementRef;
  @ViewChild("videoModal2", { static: false }) videoModal2El: ElementRef;
  @ViewChild("videoModal3", { static: false }) videoModal3El: ElementRef;
  @ViewChild("videoModal4", { static: false }) videoModal4El: ElementRef;
  @ViewChild("modalContent1", { static: false }) modalContent1El: ElementRef;
  @ViewChild("modalContent2", { static: false }) modalContent2El: ElementRef;
  @ViewChild("modalContent3", { static: false }) modalContent3El: ElementRef;
  @ViewChild("modalContent4", { static: false }) modalContent4El: ElementRef;
  @ViewChild("bulletWrapper", { static: false }) bulletWrapperEl: ElementRef;

  slides: Slide[];
  interval: any;
  storagePath: string = environment.storagePath;

  dynamicContent: DynamicContent;

  posts: Post[];
  jobOffers: JobOffer[];
  partners: Organization[];

  selectedSlideIndex = 0;
  selectedJobOfferIndex = 0;
  selectedTeamSlideIndex = 0;

  showVideoModal1: boolean;
  showVideoModal2: boolean;
  showVideoModal3: boolean;
  showVideoModal4: boolean;

  videoApis: {
    video1: VgAPI;
    video2: VgAPI;
    video3: VgAPI;
    video4: VgAPI;
  } = { video1: null, video2: null, video3: null, video4: null };

  isMobile: boolean;
  isTablet: boolean;

  isBrowser: boolean;

  @HostListener("window:resize", [])
  onResize() {
    if (this.isBrowser) {
      this.isMobile = window.innerWidth < appConstants.TOUCH_BREAKPOINT;
      this.isTablet =
        !this.isMobile && window.innerWidth < appConstants.DESKTOP_BREAKPOINT;
    }
  }

  constructor(
    private resourceService: ResourceService,
    private dynamicContentService: DynamicContentService,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.metaService.setTags("home");
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    // Slider

    if (this.isBrowser) {
      TweenLite.to(this.greyLayerEl.nativeElement, 0.8, {
        width: "66.66666%",
        ease: Power2.easeInOut
      });
    }

    this.resourceService.list("slides").subscribe(slideRes => {
      this.slides = slideRes;

      if (this.slides.length) {
        this.metaService.setImage(this.slides[0].image.openGraph);
      }

      this.launchSliderInterval();
    });

    this.dynamicContentService.dynamicContentSubject.subscribe(
      (dynamicContent: DynamicContent) => {
        this.dynamicContent = dynamicContent;
      }
    );

    let organizationParams = new HttpParams();
    organizationParams = organizationParams.set(
      "type",
      OrganizationType.Partner
    );
    organizationParams = organizationParams.set("withoutPaginator", "true");
    this.resourceService
      .list("organizations", organizationParams)
      .subscribe((partnerRes: Organization[]) => {
        this.partners = partnerRes;
      });

    let postParams = new HttpParams();
    postParams = postParams.set("limitTo", "10");
    postParams = postParams.set("featuredOnly", "true");
    postParams = postParams.set("withoutPaginator", "true");
    this.resourceService
      .list("posts", postParams)
      .subscribe((postRes: Post[]) => {
        this.posts = postRes;
      });

    let jobOfferParams = new HttpParams();
    jobOfferParams = jobOfferParams.set("limitTo", "10");
    jobOfferParams = jobOfferParams.set("featuredOnly", "true");
    jobOfferParams = jobOfferParams.set("withoutPaginator", "true");
    this.resourceService
      .list("job-offers", jobOfferParams)
      .subscribe((jobOfferRes: JobOffer[]) => {
        this.jobOffers = jobOfferRes;
      });

    if (this.isBrowser) {
      this.isMobile = window.innerWidth < appConstants.TOUCH_BREAKPOINT;
      this.isTablet =
        !this.isMobile && window.innerWidth < appConstants.DESKTOP_BREAKPOINT;
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  launchSliderInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (this.selectedSlideIndex < this.slides.length - 1) {
        this.selectedSlideIndex++;
      } else {
        this.selectedSlideIndex = 0;
      }
      this.animateSlider();
    }, appConstants.HOME_SLIDER_INTERVAL);
  }

  showSlide(i: number) {
    this.selectedSlideIndex = i;
    this.animateSlider();
    this.launchSliderInterval();
  }

  animateSlider(): void {
    if (this.isBrowser) {
      this.slideEls.forEach((slide: SlideComponent, index: number) => {
        if (index === this.selectedSlideIndex) {
          slide.appear();
        } else {
          slide.disappear();
        }
      });
      const timeline = new TimelineLite();

      timeline.set(this.bulletWrapperEl.nativeElement, {
        className: "+=no-event"
      });
      timeline.set(
        this.bulletWrapperEl.nativeElement,
        {
          className: "-=no-event"
        },
        "+=1.2"
      );
    }
  }

  fadeUp(element: ElementRef): void {
    if (this.isBrowser) {
      TweenLite.to(element.nativeElement, 1, {
        opacity: "1",
        top: "0",
        delay: 0.25,
        ease: Power2.easeInOut
      });
    }
  }
  fadeDown(element: ElementRef): void {
    if (this.isBrowser) {
      TweenLite.to(element.nativeElement, 1, {
        opacity: "1",
        top: "0",
        delay: 0.25,
        ease: Power2.easeInOut
      });
    }
  }
  fadeRight(element: ElementRef): void {
    if (this.isBrowser) {
      TweenLite.to(element.nativeElement, 1, {
        opacity: "1",
        left: "0",
        delay: 0.25,
        ease: Power2.easeInOut
      });
    }
  }
  fadeLeft(element: ElementRef): void {
    if (this.isBrowser) {
      TweenLite.to(element.nativeElement, 1, {
        opacity: "1",
        left: "0",
        delay: 0.25,
        ease: Power2.easeInOut
      });
    }
  }

  toggleVideoModal(modalNumber: number): void {
    if (
      !this.dynamicContent ||
      !this.dynamicContent[`homeVideo${modalNumber}`]
    ) {
      return;
    }
    this[`showVideo${modalNumber}Modal`] = !this[
      `showVideo${modalNumber}Modal`
    ];
    if (this[`showVideo${modalNumber}Modal`]) {
      this.videoApis[`video${modalNumber}`].play();
    } else {
      this.videoApis[`video${modalNumber}`].pause();
    }

    if (this[`showVideo${modalNumber}Modal`]) {
      if (this.isBrowser) {
        TweenLite.set(this[`videoModal${modalNumber}El`].nativeElement, {
          css: { className: "+=is-displayed" }
        });
        TweenLite.to(this[`modalContent${modalNumber}El`].nativeElement, 0.5, {
          opacity: "1",
          top: "50%",
          delay: 0.5
        });
      }
    } else {
      if (this.isBrowser) {
        TweenLite.set(this[`videoModal${modalNumber}El`].nativeElement, {
          css: { className: "-=is-displayed" }
        });
        TweenLite.to(this[`modalContent${modalNumber}El`].nativeElement, 0.5, {
          opacity: "0",
          top: "50% + 20px"
        });
      }
    }
  }

  onPlayerReady(vgApi: VgAPI, playerName: string): void {
    this.videoApis[playerName] = vgApi;
  }
}
