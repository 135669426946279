import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ResourceService } from '../../common/services/resource.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../app.constants'
import { MetaService } from '../../common/services/meta.service'

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
  providers: [ResourceService, MetaService]
})
export class UnsubscribeComponent implements OnInit {
  token: string
  loading: boolean
  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private flashMessagesService: FlashMessagesService,
    private router: Router,
    private metaService: MetaService
  ) {
    this.metaService.setTags('unsubscribe')
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params.token
  }

  unsubscribe() {
    this.resourceService
      .store(`subscribers/${this.token}/unsubscribe`, new FormData())
      .subscribe(
        res => {
          this.router.navigate(['/'])
          this.flashMessagesService.show(
            `Vous vous êtes désinscrit de la newsletter`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        },
        err => {
          this.flashMessagesService.show(
            `Nous n'avons pas pu vous désinscrire de la newsletter`,
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
  }
}
