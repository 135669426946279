import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  HostListener,
  PLATFORM_ID,
  Inject
} from '@angular/core'
import { Post } from '../../../common/interfaces/post'
import { TweenLite, Expo, Power2 } from 'gsap'
import { appConstants } from '../../../app.constants'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-post-carousel',
  templateUrl: './post-carousel.component.html',
  styleUrls: ['./post-carousel.component.scss']
})
export class PostCarouselComponent implements OnInit {
  @Input() posts: Post[]
  @Input() layout: string

  @ViewChild('carouselContainer', { static: false })
  carouselContainerEl: ElementRef

  isMobile: boolean
  isTablet: boolean

  offsetPercentage: number
  maxPostIndex: number
  selectedPostIndex = 0

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {
    this.setResponsiveValues()
  }

  goToPost(index: number) {
    TweenLite.to(this.carouselContainerEl.nativeElement, 0.65, {
      x: '-' + (this.offsetPercentage * index).toString() + '%',
      ease: Expo.easeOut
    })

    this.selectedPostIndex = index
  }

  setResponsiveValues(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < appConstants.TOUCH_BREAKPOINT
      this.isTablet =
        !this.isMobile && window.innerWidth < appConstants.DESKTOP_BREAKPOINT

      this.offsetPercentage = this.isMobile ? 100 : 50

      if (this.isMobile) {
        this.maxPostIndex = this.posts.length - 1
      } else if (this.isTablet) {
        this.maxPostIndex = this.posts.length - 2
      } else {
        this.maxPostIndex = this.posts.length - 2
      }
    }
  }
  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.25,
      ease: Power2.easeInOut
    })
  }
  fadeLeft(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.25,
      ease: Power2.easeInOut
    })
  }

  @HostListener('window:resize', [])
  onResize() {
    this.setResponsiveValues()
    // We return to the first post on resize to prevent being in the middle of 2 posts
    this.goToPost(0)
  }
}
