import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  ElementRef
} from '@angular/core'
import { TweenLite, Power2 } from 'gsap'
import { Testimonial } from '../../../common/interfaces/testimonial'

@Component({
  selector: 'app-testimonial-carousel',
  templateUrl: './testimonial-carousel.component.html',
  styleUrls: ['./testimonial-carousel.component.scss']
})
export class TestimonialCarouselComponent implements AfterViewInit {
  @Input() testimonials: Testimonial[]
  @ViewChild('carousel', { static: false }) carouselEl: ElementRef

  selectedTestimonialIndex = 0
  images: { portraitRetina: string; portraitMobile: string }[]

  ngAfterViewInit() {
    // Make first slide appear
    const newSlide = this.carouselEl.nativeElement.querySelector(
      `.carousel-item.index-${this.selectedTestimonialIndex}`
    )
    TweenLite.to(newSlide, 0.3, {
      className: '+=is-active',
      left: '0',
      opacity: '1'
    })
  }

  goToSlide(slideNumber: number) {
    this.selectedTestimonialIndex = slideNumber

    // Make active slide disappear
    const activeSlide = this.carouselEl.nativeElement.querySelector(
      '.carousel-item.is-active'
    )
    TweenLite.to(activeSlide, 0.3, {
      className: '-=is-active',
      opacity: '0',
      left: '-20'
    })
    TweenLite.set(activeSlide, {
      left: '20',
      delay: 0.3
    })

    // Make new slide appear
    const newSlide = this.carouselEl.nativeElement.querySelector(
      `.carousel-item.index-${slideNumber}`
    )
    TweenLite.to(newSlide, 0.3, {
      className: '+=is-active',
      left: '0',
      opacity: '1',
      delay: 0.3
    })
  }

  fadeIn(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      top: '0',
      delay: 0.5,
      ease: Power2.easeInOut
    })
  }
}
