import { Component } from '@angular/core'
import { MetaService } from '../../common/services/meta.service'

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
  providers: [MetaService]
})
export class Error404Component {
  constructor(private metaService: MetaService) {
    this.metaService.setTags('error404')
  }
}
