import { Directive, Input, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { EventService } from '../services/event.service'
import { appConstants } from '../../app.constants'

@Directive({
  selector: '[appDelayedRouterLink]'
})

// This directive waits a delay (specified in appConstants) and navigate to next route as a [routerLink]
export class DelayedRouterLinkDirective {
  @Input('appDelayedRouterLink') appDelayedRouterLink: any
  @Input('queryParams') queryParams: {}

  constructor(private router: Router, private eventService: EventService) {}

  @HostListener('click', ['$event']) onClick($event) {
    this.eventService.delayedRouteChanged.next(true)

    setTimeout(() => {
      this.router.navigate(this.appDelayedRouterLink, {
        queryParams: this.queryParams,
        queryParamsHandling: 'merge'
      })
    }, appConstants.ROUTE_CHANGE_DELAY)
  }
}
