import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})

/** This service is the default service for simple resources. To keep things DRY, it's recommended to use it instead
 * instead of custom ones as often as possible.
 */
export class ResourceService {
  constructor(private http: HttpClient) {}

  list(resourceName: string, params?: HttpParams): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + resourceName, {
        params: params
      })
      .pipe(
        map(res => {
          return res
        })
      )
  }

  show(resourceName: string, id: number): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}${resourceName}/${id}`).pipe(
      map(res => {
        return res
      })
    )
  }

  // Resources that have detail pages are fetched using slug instead of ID for SEO purposes
  showBySlug(resourceName: string, slug: string): Observable<any> {
    return this.http
      .get(`${environment.apiBaseUrl}${resourceName}/${slug}`)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  store(resourceName: string, formData: FormData): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}${resourceName}`, formData)
      .pipe(
        map(response => {
          return response
        })
      )
  }
}
