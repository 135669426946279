import {
  Component,
  ViewEncapsulation,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
  Inject
} from '@angular/core'
import { Subscription } from 'rxjs'
import { EventService } from './common/services/event.service'
import { Router, NavigationEnd } from '@angular/router'
import { DynamicContentService } from './common/services/dynamic-content.service'
import { DynamicContent } from './common/interfaces/dynamic-content'

import objectFitImages from 'object-fit-images'
import { TimelineLite } from 'gsap'
import { Power2 } from 'gsap'
import { appConstants } from './app.constants'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../assets/styles/main.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('curtainLeft', { static: true }) curtainLeftEl: ElementRef
  @ViewChild('curtainRight', { static: true }) curtainRightEl: ElementRef
  @ViewChild('html', { static: true }) htmlEl: ElementRef

  private eventSubscriptions = new Subscription()
  private subscription = new Subscription()

  ngOnInit() {
    this.subscription.add(
      this.eventService.routeChanged.subscribe(routeChanged => {
        // Scroll top
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0)
        }
      })
    )

    this.eventService.delayedRouteChanged.subscribe(isNavigation => {
      if (isPlatformBrowser(this.platformId)) {
        // Only on navigation, not on first arrival
        if (isNavigation) {
          const tl = new TimelineLite()
          tl.set(this.curtainLeftEl.nativeElement, {
            opacity: 1
          })
          tl.set(this.curtainRightEl.nativeElement, {
            opacity: 1
          })
          // Delayed route animation
          tl.to(
            this.curtainLeftEl.nativeElement,
            appConstants.ROUTE_CHANGE_DELAY / 1000,
            {
              className: '+=on-transition',
              ease: Power2.easeInOut
            }
          )
          tl.to(
            this.curtainRightEl.nativeElement,
            appConstants.ROUTE_CHANGE_DELAY / 1000,
            {
              className: '+=on-transition',
              ease: Power2.easeInOut
            },
            '-=appConstants.ROUTE_CHANGE_DELAY / 1000'
          )
        }
      }
    })

    // Load dynamic content once
    this.dynamicContentService
      .loadDynamicContent()
      .subscribe((dynamicContent: DynamicContent) => {
        this.dynamicContentService.dynamicContentSubject.next(dynamicContent)
      })
  }
  constructor(
    private router: Router,
    private eventService: EventService,
    private dynamicContentService: DynamicContentService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.eventSubscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.eventService.routeChanged.next({ url: event.url })

          if (isPlatformBrowser(this.platformId)) {
            // End navigation animation
            const timeline = new TimelineLite()

            // Delayed route animation
            timeline.to(
              this.curtainLeftEl.nativeElement,
              (appConstants.ROUTE_CHANGE_DELAY / 1000) * 2,
              {
                ease: Power2.easeInOut,
                opacity: 0
              }
            )
            timeline.to(
              this.curtainRightEl.nativeElement,
              (appConstants.ROUTE_CHANGE_DELAY / 1000) * 2,
              {
                ease: Power2.easeInOut,
                opacity: 0
              },
              '-=appConstants.ROUTE_CHANGE_DELAY / 1000 * 2'
            )
            timeline.set(this.curtainLeftEl.nativeElement, {
              className: '-=on-transition'
            })
            timeline.set(this.curtainRightEl.nativeElement, {
              className: '-=on-transition'
            })
          }
        }
      })
    )
  }

  ngAfterViewInit() {
    // Object fit images polyfill https://github.com/bfred-it/object-fit-images
    objectFitImages()
  }
}
