import { Injectable } from '@angular/core'
import { DynamicContent } from '../interfaces/dynamic-content'
import { Observable, BehaviorSubject } from 'rxjs'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class DynamicContentService {
  public dynamicContentSubject = new BehaviorSubject({} as DynamicContent)

  constructor(private http: HttpClient) {}

  loadDynamicContent(): Observable<DynamicContent> {
    return this.http.get(environment.apiBaseUrl + 'dynamic-contents/1').pipe(
      map((res: DynamicContent) => {
        return res
      })
    )
  }
}
