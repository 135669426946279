import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
  PLATFORM_ID,
  Inject
} from '@angular/core'
import { JobOffer } from '../../../common/interfaces/job-offer'
import { appConstants } from '../../../app.constants'
import { TweenLite, Expo, Power2 } from 'gsap'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-job-offer-carousel',
  templateUrl: './job-offer-carousel.component.html',
  styleUrls: ['./job-offer-carousel.component.scss']
})
export class JobOfferCarouselComponent implements OnInit {
  @Input() jobOffers: JobOffer[]
  @ViewChild('carouselContainer', { static: false })
  carouselContainerEl: ElementRef

  isMobile: boolean
  isTablet: boolean

  maxJobOfferIndex: number
  offsetPercentage: number
  selectedJobOfferIndex = 0

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {
    this.setResponsiveValues()
  }

  goToJobOffer(index: number) {
    TweenLite.to(this.carouselContainerEl.nativeElement, 0.525, {
      x: '-' + (this.offsetPercentage * index).toString() + '%',
      ease: Expo.easeOut
    })

    this.selectedJobOfferIndex = index
  }

  setResponsiveValues(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < appConstants.TOUCH_BREAKPOINT
      this.isTablet =
        !this.isMobile && window.innerWidth < appConstants.DESKTOP_BREAKPOINT
    }

    this.offsetPercentage = this.isMobile ? 100 : 33.33333

    if (this.isMobile) {
      this.maxJobOfferIndex = this.jobOffers.length - 1
    } else if (this.isTablet) {
      this.maxJobOfferIndex = this.jobOffers.length - 3
    } else {
      this.maxJobOfferIndex = this.jobOffers.length - 4
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.setResponsiveValues()
    // We return to the first post on resize to prevent being in the middle of 2 posts
    this.goToJobOffer(0)
  }
  fadeRight(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.25,
      ease: Power2.easeInOut
    })
  }
  fadeLeft(element: ElementRef): void {
    TweenLite.to(element.nativeElement, 1, {
      opacity: '1',
      left: '0',
      delay: 0.25,
      ease: Power2.easeInOut
    })
  }
}
