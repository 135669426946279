import { Routes } from '@angular/router'
import { HomeComponent } from './pages/home/home.component'
import { Error404Component } from './errors/error404/error404.component'
import { ContactComponent } from './pages/contact/contact.component'
import { AboutComponent } from './pages/about/about.component'
import { TeamComponent } from './pages/team/team.component'
import { PostListComponent } from './resources/posts/post-list/post-list.component'
import { PostDetailComponent } from './resources/posts/post-detail/post-detail.component'
import { StudyListComponent } from './resources/studies/study-list/study-list.component'

import { JobOfferListComponent } from './resources/job-offers/job-offer-list/job-offer-list.component'
import { JobOfferDetailComponent } from './resources/job-offers/job-offer-detail/job-offer-detail.component'
import { LegalTermsComponent } from './pages/legal-terms/legal-terms.component'
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component'
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component'
import { ServicesComponent } from './pages/services/services.component'
import { Department } from './common/enums/department'

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'a-propos',
    component: AboutComponent
  },
  {
    path: 'l-equipe',
    component: TeamComponent
  },
  {
    path: 'actualites',
    component: PostListComponent
  },
  {
    path: 'actualites/:slug',
    component: PostDetailComponent
  },
  {
    path: 'references',
    component: StudyListComponent
  },
  {
    path: 'offres-d-emploi',
    component: JobOfferListComponent
  },
  {
    path: 'offres-d-emploi/:slug',
    component: JobOfferDetailComponent
  },
  {
    path: 'mentions-legales',
    component: LegalTermsComponent
  },
  {
    path: 'politique-de-confidentialite',
    component: PrivacyPolicyComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'prestations/r-et-e/etudes-historiques',
    component: ServicesComponent,
    data: {
      service: 'historicalStudies',
      label: 'Études historiques',
      department: Department.RAndE
    }
  },
  {
    path: 'prestations/r-et-e/expertise-et-assistance-a-maitrise-d-ouvrage',
    component: ServicesComponent,
    data: {
      service: 'expertise',
      label: `Expertise et assistance à maîtrise d'ouvrage`,
      department: Department.RAndE
    }
  },
  {
    path: 'prestations/i-doc/archives',
    component: ServicesComponent,
    data: {
      service: 'archives',
      label: 'Archives',
      department: Department.IDoc
    }
  },
  {
    path: 'prestations/i-doc/bibliotheques',
    component: ServicesComponent,
    data: {
      service: 'libraries',
      label: 'Bibliothèques',
      department: Department.IDoc
    }
  },
  {
    path: 'prestations/i-doc/musees',
    component: ServicesComponent,
    data: {
      service: 'museums',
      label: 'Musées',
      department: Department.IDoc
    }
  },
  {
    path: 'prestations/i-doc/documentation',
    component: ServicesComponent,
    data: {
      service: 'documentation',
      label: 'Documentation',
      department: Department.IDoc
    }
  },
  {
    path: 'prestations/i-pat/gestion-urbaine-et-paysagere-du-patrimoine',
    component: ServicesComponent,
    data: {
      service: 'urbanManagement',
      label: 'Gestion urbaine et paysagère du patrimoine',
      department: Department.IPat
    }
  },
  {
    path: 'prestations/i-pat/protection-et-labellisation-de-patrimoines',
    component: ServicesComponent,
    data: {
      service: 'protection',
      label: 'Protection et labellisation de patrimoines',
      department: Department.IPat
    }
  },
  {
    path: 'prestations/i-pat/patrimoine-mondial',
    component: ServicesComponent,
    data: {
      service: 'worldHeritage',
      label: 'Patrimoine mondial',
      department: Department.IPat
    }
  },
  {
    path: 'prestations/i-pat/projets-museologiques-culturels-et-patrimoniaux',
    component: ServicesComponent,
    data: {
      service: 'museumProjects',
      label: 'Projets muséologiques, culturels et patrimoniaux',
      department: Department.IPat
    }
  },
  {
    path: 'prestations/grahal-academie/formations',
    component: ServicesComponent,
    data: {
      service: 'formationName',
      label: 'Formations',
      department: Department.Formation
    }
  },
  {
    path: 'desinscription/:token',
    component: UnsubscribeComponent
  },
  {
    path: '404',
    component: Error404Component
  },
  {
    path: '**',
    redirectTo: '/404'
  }
]
